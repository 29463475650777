import React from 'react';
import { Form, Field } from 'react-final-form'
import { withTranslation } from 'react-i18next';

import { BEM } from '@bikejs/react-bem';

import InputRadio from 'components/InputRadio';
import Button from 'components/Button';

import { phoneMask, emailMask } from 'common/helpers/string.helper';
import { sessionStorage } from 'common/services/storage.service';
import { withLoader } from 'components/Loader/redux';
import { Auth } from 'common/redux/auth';

import './index.scss';

@withTranslation()
@BEM('recovery-step-two-form')
export class RecoveryStepTwoForm extends React.PureComponent {
  static displayName = 'RecoveryStepTwoForm';

  componentDidMount() {
    if (!sessionStorage.get('recovery.first-response')) {
      this.props.history.push('/recovery');
    }
  }

  async onSubmit(values) {
    const data = await withLoader('auth', Auth.recoverySendOTP, Auth)({
      body: values
    });

    if (data.next) {
      this.props.history.push(data.next.path);
    }
  }

  render() {
    const { bem, t } = this.props;
    const phones = Object.entries(sessionStorage.get('recovery.first-response', []));

    return (
      <Form
        onSubmit={values => this.onSubmit(values)}
        initialValues={{
          sendBy: phones.length !== 0 ? phones[0].name : undefined,
        }}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} className={bem()}>
            <div className={bem('sub')}>
              {t('Оберіть зручний спосіб отримання коду для зміни пароля')}
            </div>
            {phones.map(([key, value]) => (
              <div key={key} className={bem('row')}>
                <Field
                  name="sendBy"
                  type="radio"
                  component={InputRadio}
                  label={(
                    <div className={bem('label')}>
                      {value}
                    </div>
                  )}
                  value={key}
                />
              </div>
            ))}
            <footer className={bem('footer')}>
              <Button type="submit" disabled={submitting} block>
                {t('Продовжити')}
              </Button>
            </footer>
          </form>
        )}
      />
    );
  }
}
